import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import '../../styles/filtraggio.css';


export default function Filtraggio( props ){

    const setRicerca = props.setRicerca;

    return (
        <div className="filtraggioContainer">
            <input type="text" placeholder="Cerca sede..." onChange={(e) => setRicerca(e.target.value)}></input>
            <FontAwesomeIcon
                className="searchIcon" 
                icon={faSearch}
                size="lg"
            />
        </div>
    );
};