
import React, { useEffect, useState } from 'react';
import '../../styles/prenotazioni.css'

const Prenotazioni = () => {

    //hooks
    const [sediObj, setSediObject] = useState({});

    //variabili d'utility
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = dd + '/' +  mm + '/' + yyyy;

    //chiamataAPi
    const getTodayPrenotationAPI = '/api/v1/getTodayPrenotation';

    useEffect(() => {

        let objSedi = {
            'roma_Via_Tensi': [],
            'guidonia': [],
            'pescara': [],
            'GAGA': [],
            'empoli': [],
            'milano_Via_Fantoli': [],
            'firenze': [],
            'calcinate': [],
            'acquaviva_delle_Fonti': [],
            'capurso': [],
            'pisa': [],
            'altopascio': [],
        }

        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

    fetch(getTodayPrenotationAPI, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log('result', result)
            if(result.day.length === 0){
                return;
            }
            Object.values(result.day).map(prenotazioni => {
                console.log('prenotazioni', prenotazioni/* , objSedi[prenotazioni.sede] */)
                objSedi[prenotazioni.sede].push(prenotazioni)
            })
            setSediObject(objSedi)
            /* console.log('objSedi', objSedi) */
        })
        .catch(error => console.log('error', error));
    }, [])

    console.log('sediObj fuori', sediObj)

    return (
        <div className='prenotazioniContainer'>
            <h2>Prenotazioni del giorno {today}</h2>
            {
                Object.values(sediObj).length === 0 ? 
                <p className='noPrenotation'>Non ci sono prenotazioni</p>
                :
                Object.values(sediObj).map((sede, i) => {
                    return (
                        <div className='sediContainer' key={i}>
                            <p>{sede.length == 0 ? null : sede[0].sede.replace(/_/g, ' ')}</p>
                            {
                                Object.values(sede).map((user, k) => {
                                    return (
                                        <div className='userContainer' key={k}>
                                            <p>{user.username}, {user.ora}</p>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    )
                })
            }
        </div>
    );
};

export default Prenotazioni;