import React, { useEffect } from "react";

import '../../styles/checkPrenotation.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

export default function CheckPrenotation( props ){

    const oraPrenotata = props.oraPrenotata;
    const minutiPrenotati = props.minutiPrenotati;
    const setTesto = props.setTesto;
    const setShowQuestionDelete = props.setShowQuestionDelete;
    const setShowIcons = props.setShowIcons;
    const setAnswerYes = props.setAnswerYes;
    const setAnswerNo = props.setAnswerNo;
    const setShowQuestionPrenotation = props.setShowQuestionPrenotation;
    const showOk = props.showOk;
    const setShowOk = props.setShowOk;
    const showX = props.showX;
    const setShowX = props.setShowX;
    const setShowHours = props.setShowHours;
    const setMobileHourToggler = props.setMobileHourToggler;
    const setShowInitialDiv = props.setShowInitialDiv;

    const SwitchIcon1 = (e) => {
        e.preventDefault();
        
        setShowX('flex');
        setShowOk('none');
    }

    const SwitchIcon2 = (e) => {
        e.preventDefault();

        setShowX('none');
        setShowOk('flex');
    }

    const askDeletePrenotation = (e) => {
        e.preventDefault();

        setMobileHourToggler('flex');
        setShowHours(true);
        setShowIcons('none');
        setTesto('Vuoi annullare la prenotazione?');
        setShowQuestionPrenotation('none');
        setShowQuestionDelete('flex');
        setAnswerYes('block');
        setAnswerNo('block');
        setShowInitialDiv('none');
    };

    return (
        <div 
            className="reminderPrenotation" 
            onMouseEnter={(e) => SwitchIcon1(e)} 
            onMouseLeave={(e) => SwitchIcon2(e)}>
                <div className="abortText" 
                    style={{display: showX}}>
                    Vuoi annullare?
                </div>
            <FontAwesomeIcon 
                className="checkCard"
                style={{display: showOk}}
                icon={faCheckCircle}
                size="1x"
            />
            <div 
                className="orarioPrenotazione" 
                style={{display: showOk}}>Orario: {oraPrenotata}:{minutiPrenotati}
            </div>
            <FontAwesomeIcon 
                className="abortedCard"
                style={{display: showX}}
                onClick={(e) => askDeletePrenotation(e)}
                icon={faTimesCircle}
                size="2x"
            />
        </div>
    );
};