import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

import '../../styles/dashboard.css';
import Header from '../header/header';
import Lista from '../lista/lista';


export default function Dashboard(){

    const user = localStorage.getItem('user')
    const history = useHistory();
    const [score, setScore] = useState(0);
    const [activeConfetti, setActiveConfetti] = useState(false);
    const [nomeSede, setNomeSede] = useState('');
    const [nomeStanza, setNomeStanza] = useState('');
    const [stanzaId, setStanzaId] = useState('');
    const [oraPrenotata, setOraPrenotata] = useState('');
    const [minutiPrenotati, setMinutiPrenotati] = useState('');
    const [testo, setTesto] = useState('Prenota il tuo spot in una delle sale da pranzo aziendali');
    const [showOk, setShowOk] = useState('none');

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = dd + '/' + mm + '/' + yyyy;

    useEffect(() => {
        if(user == null){
            history.push('/')
        }
    }, [])

    //Chiamata API
    const getCards = '/api/v1/room';
    const getScore = '/api/v1/game';
    const getPrenotation = '/api/v1/prenotation';

    //Hooks
    const [sede, setSede] = useState([]);
    const [ricerca, setRicerca] = useState('');
    const [toggler, setToggler] = useState(true) //false non si vede preferiti
    const [widthMobile, setWidthMobile] = useState(0);

    //useEffect per prelevare i dati per le card
    useEffect(() => {

        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(getCards, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log('fetch result dal BE', result.items.sede);

                const sedi = result.items.sede;

                setSede(sedi);
            })
    }, [])

    //useEffect per prelevare lo score del giocatore
    useEffect(() => {

        let parsedUser = JSON.parse(user)

        /* console.log('user', parsedUser) */
        var myHeaders = new Headers();
        myHeaders.append("email", parsedUser.email);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(getScore, requestOptions)
            .then(response => response.json())
            .then(result => {

                console.log(result)

                if(result.playerExp){
                    setScore(result.playerExp)
                } else {
                    setScore(0)
                }

            })
            .catch(error => console.log('error', error));

    }, [])

    //useEffect per far partire l'animazione dei confetti al raggiungimento dei 1000 punti
    useEffect(() => {
        if(score == 1000){
            setActiveConfetti(true);
            setTimeout(() => {
                setActiveConfetti(false);
            }, 5000)
        }
    }, [score])

    //useEffect per prelevare i dati dal DB affinchè si possa vedere le prenotazioni effettuate
    useEffect(() => {

        let parsedUser = JSON.parse(user)

        var myHeaders = new Headers();
        myHeaders.append("giorno", today);
        myHeaders.append("email", parsedUser.email);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(getPrenotation, requestOptions)
            .then(response => response.json())
            .then(result => {

                console.log('result', result)

                setOraPrenotata(result.ora.split(':')[0]);
                setMinutiPrenotati(result.ora.split(':')[1]);
                setNomeSede(result.sede)
                setNomeStanza(result.stanza)
                setTesto('Hai prenotato alle ' + result.ora.split(':')[0] + ':' + result.ora.split(':')[1] + ' nella sede di ' + result.sede[0].toUpperCase() + result.sede.substr(1).split('_').join(' ') + '.\nBuon appetito!');
                setShowOk('block');
                setStanzaId(result.stanzaId);

                let stanzaIdElement = document.querySelector('#' + result.stanzaId + ' .stanzaBtns .reminderPrenotation');
                stanzaIdElement.style.display = 'flex';
                let buttonPrenota = document.querySelector('#' + result.stanzaId + ' .stanzaBtns .btnPrenota');
                buttonPrenota.style.display = 'none';
                let buttonAnnulla = document.querySelector('#' + result.stanzaId + ' .stanzaBtns .btnAnnulla');
                buttonAnnulla.style.display = 'none';

            })
            .catch(error => console.log('error', error));

    }, [])

    return (
        <>
            <Header 
                setRicerca={setRicerca}
                toggler={toggler}
                setToggler={setToggler}
                widthMobile={widthMobile}
                score={score}
                activeConfetti={activeConfetti}
            />
            <div className="dashboardPage">
                <Lista 
                    sede={sede} 
                    ricerca={ricerca}
                    toggler={toggler}
                    widthMobile={widthMobile}
                    setWidthMobile={setWidthMobile}
                    setScore={setScore}
                    nomeSede={nomeSede}
                    setNomeSede={setNomeSede}
                    nomeStanza={nomeStanza}
                    setNomeStanza={setNomeStanza}
                    stanzaId={stanzaId}
                    setStanzaId={setStanzaId}
                    oraPrenotata={oraPrenotata}
                    setOraPrenotata={setOraPrenotata}
                    minutiPrenotati={minutiPrenotati}
                    setMinutiPrenotati={setMinutiPrenotati}
                    testo={testo}
                    setTesto={setTesto}
                    showOk={showOk}
                    setShowOk={setShowOk}
                />
            </div>
        </>
    );
}