import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';

import '../../styles/header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faStar, faCalendarAlt, faBook } from "@fortawesome/free-solid-svg-icons";
import Filtraggio from "../filtraggio/filtraggio";
import imgSandwich from '../../static/sandwich.png'
import Confetti from 'react-confetti'




export default function Header( props ){

    //props
    const setRicerca = props.setRicerca;
    const toggler = props.toggler;
    const setToggler = props.setToggler;
    const widthMobile = props.widthMobile;
    const score = props.score;
    const activeConfetti = props.activeConfetti;
    
    //hooks
    const [starFavorites, setStarFavorites] = useState('#fff');
    const [testoPreferiti] = useState('preferiti');
    const [showTooltip, setShowTooltip] = useState('none');
    
    //variabile del local storage
    const user = JSON.parse(localStorage.getItem('user'));

    const history = useHistory();

    // useEffect(() => {
    //     switch(user.email){
    //         case 'fabio.caruso@avrgroup.it':
    //             user.nome = 'Cicoria 🥬';
    //             break;
    //         case 'lorenzo.pieri@avrgroup.it':
    //             user.nome = 'Tacchino 🦃';
    //             break;
    //         case 'giorgia.mazzini@avrgroup.it':
    //             user.nome = 'Amio ❤️';
    //             break;
    //         case 'gianluca.bianchini@avrgroup.it':
    //             user.nome = 'Beppino ❤️';
    //             break;
    //         case 'alessandra.bartolini@avrgroup.it':
    //             user.nome = 'Sposa ❤️';
    //             break;
    //         case 'benedetta.vanni@avrgroup.it':
    //             user.nome = 'Ciucchina ❤️';
    //             break;
    //         case 'giorgio.corona@avrgroup.it':
    //             user.nome = 'Bellissimo ❤️';
    //             break;
    //         case 'tommaso.chiti@avrgroup.it':
    //             user.nome = 'Secsy ❤️';
    //             break;
    //         case 'cecilia.chiarini@avrgroup.it':
    //             user.nome = 'Nacchera ❤️';
    //             break;
    //         case 'gabriele.cara@avrgroup.it':
    //             user.nome = 'Gabri ❤️';
    //             break;
    //         case 'federica.pascucci@avrgroup.it':
    //             user.nome = 'Fede ❤️';
    //             break;
    //         default:
    //             break;
    //     }

    // }, [])

    switch(user.email){
        case 'fabio.caruso@avrgroup.it':
            user.nome = 'Cicoria 🥬';
            break;
        case 'lorenzo.pieri@avrgroup.it':
            user.nome = 'Tacchino 🦃';
            break;
        case 'giorgia.mazzini@avrgroup.it':
            user.nome = 'Amio ❤️';
            break;
        case 'gianluca.bianchini@avrgroup.it':
            user.nome = 'Beppino ❤️';
            break;
        case 'alessandra.bartolini@avrgroup.it':
            user.nome = 'Sposa ❤️';
            break;
        case 'benedetta.vanni@avrgroup.it':
            user.nome = 'Ciucchina ❤️';
            break;
        case 'giorgio.corona@avrgroup.it':
            user.nome = 'Bellissimo ❤️';
            break;
        case 'tommaso.chiti@avrgroup.it':
            user.nome = 'Secsy ❤️';
            break;
        case 'cecilia.chiarini@avrgroup.it':
            user.nome = 'Nacchera ❤️';
            break;
        case 'gabriele.cara@avrgroup.it':
            user.nome = 'Gabri ❤️';
            break;
        case 'federica.pascucci@avrgroup.it':
            user.nome = 'Fede ❤️';
            break;
        default:
            break;
    }
    
    
    const ToggleFavorites = (e) => {
        e.preventDefault();

        var divs = document.querySelectorAll(".sedeWrapper")

        if(toggler === true){
            divs.forEach(item => {
                let sede = item.getAttribute("data-name")

                var resultLS = localStorage.getItem(sede)

            if(resultLS != null){
                item.classList.add("preferiti");
            } else {
                item.classList.add("invisibili");
            }
            setToggler(false)
            setStarFavorites('gold');
        })
        } else {
            divs.forEach(item => {
                item.classList.remove("invisibili")
            })
            setToggler(true)
            setStarFavorites('#fff');
        }
    };

    /* const clickScore = (e) => {

        activeConfetti ? setActiveConfetti(false) : setActiveConfetti(true);
        console.log(activeConfetti);
    }; */

    const ShowTooltip = () => {
        if(widthMobile < 767){
            return;
        }
        setShowTooltip('block');
    };

    const HideTooltip = () => {
        if(widthMobile < 767){
            return;
        }
        setShowTooltip('none');
    };

    const Logout = () => {
        let loggedUser = localStorage.getItem('user')
        if(loggedUser != null){
            localStorage.removeItem('user');
            history.push('/')
        }
    };

    const OpenPrenotazioni = () => {
        history.push('/prenotazioni')
    };

    //se score è uguale a 1000 deve attivare i confetti

    

    const [size, setSize] = useState({
        x: window.innerWidth,
        y: window.innerHeight
    });

    const updateSize = () =>
    setSize({
        x: window.innerWidth,
        y: window.innerHeight
    });

    useEffect(() => (
        window.onresize = updateSize
    ), [])


    return (
        <div className="headerWrapper">
            {
                activeConfetti == false ? null : <Confetti width={size.x} height={size.y} />
            }
            
            <div className="headerCenter">
                <div className="textContainer">
                    <div>
                        <h1 className="text1">Ciao, {user ? user.nome : ''}</h1>
                        <h2 className="text2">prenota il tuo posto nei locali AVR</h2>
                    </div>
                    <div className="score"/*  onClick={() => clickScore()} */>
                        <p>I tuoi punti</p>
                        <img src={imgSandwich} alt="sandwich_image" />
                        <span>{score}</span>
                    </div>
                </div>
                <div className="filtraggio">
                    <Filtraggio setRicerca={setRicerca} />
                    <FontAwesomeIcon 
                        icon={faStar}
                        style={{color: starFavorites}}
                        onClick={(e) => ToggleFavorites(e)}
                        onMouseEnter={() => ShowTooltip()}
                        onMouseLeave={() => HideTooltip()}
                        size="lg"
                        className="starFiltraggio"
                    />
                    <FontAwesomeIcon
                        className="calendarIcon"
                        icon={faCalendarAlt}
                        size="lg"
                        onClick={(e) => OpenPrenotazioni(e)}
                    />
                    <a href="/public/Spaces2021.pdf"><FontAwesomeIcon
                        className="bookIcon"
                        icon={faBook}
                        size="lg"
                    /></a>
                    <FontAwesomeIcon 
                        className="logoutIcon"
                        icon={faSignOutAlt}
                        size="lg"
                        onClick={(e) => Logout(e)}
                    />
                </div>
                <div className="tooltip" style={{display: showTooltip}}>
                    {testoPreferiti}
                </div>
            
            </div>
        </div>
    );
};