import React from "react";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../styles/stanze.css';
import { faBookmark, faUsers } from "@fortawesome/free-solid-svg-icons";
import CheckPrenotation from "../checkPrenotation/checkPrenotation";


export default function Stanze( props ){

    //props
    const salaLuogo = props.salaLuogo;
    const stanze = props.sala;
    const sedeString = props.sedeString;
    const showHours = props.showHours;
    const setShowHours = props.setShowHours;
    const setArrayInfos = props.setArrayInfos;
    const setNomeSede = props.setNomeSede;
    const setNomeStanza = props.setNomeStanza;
    const setStanzaId = props.setStanzaId;
    const idString = props.sedeString + props.id;
    const oraPrenotata = props.oraPrenotata;
    const minutiPrenotati = props.minutiPrenotati;
    const setTesto = props.setTesto;
    const setShowQuestionDelete = props.setShowQuestionDelete;
    const setShowIcons = props.setShowIcons;
    const setAnswerYes = props.setAnswerYes;
    const setAnswerNo = props.setAnswerNo;
    const setShowQuestionPrenotation = props.setShowQuestionPrenotation;
    const showButton = props.showButton;
    const setShowButton = props.setShowButton
    const showOk = props.showOk;
    const setShowOk = props.setShowOk;
    const showX = props.showX;
    const setShowX = props.setShowX;
    const setShowInitialDiv = props.setShowInitialDiv;
    const setMobileHourToggler = props.setMobileHourToggler;
    const widthMobile = props.widthMobile;
    const setShowArrow = props.setShowArrow;
    const setOraPrenotata = props.setOraPrenotata;
    const setMinutiPrenotati = props.setMinutiPrenotati;

    //Chiamata API
    const getTime = '/api/v1/room';

    //Funzione che mostra il blocco con le ore
    const showChoose = (e) => {
        e.preventDefault();

        if(showHours === true || showOk === 'block'){
            return;
        } else {

        let myTarget = e.target;
        let myTargetSibling = e.target.nextElementSibling;

        if(myTarget == null || myTargetSibling == null){
            return;
        } else {

        myTarget.style.display = 'none';
        myTargetSibling.style.display = 'block';

        if(widthMobile < 767){
            if(showOk == 'none'){
                setMobileHourToggler('flex');
            setShowArrow('block');
            } else {
                return;
            }
            
        }
        setShowHours(true);
        setShowInitialDiv('none');
        setTesto('Scegli la fascia oraria in cui vuoi occupare la sala scelta di ' + sedeString[0].toUpperCase() + sedeString.substr(1).split('_').join(' '))

        setNomeSede(e.target.getAttribute('data-sede'))
        setNomeStanza(e.target.getAttribute('data-stanza'))
        setStanzaId(idString)
        //al click di prenota deve partire la fetch
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(getTime, requestOptions)
            .then(response => response.json())
            .then(result => {
                /* console.log('fetch result arrayPrenotazioni dal BE', result); */

                const dati = result.arrayPrenotazioni;

                setArrayInfos(dati);

        })
    }
    }
    };

    //Funzione che chiude il blocco con le ore
    const hideChoose = (e) => {
        e.preventDefault();
        
        let myTarget = e.target;
        let myTargetPrevious = e.target.previousElementSibling;

        if(myTarget == null || myTargetPrevious == null){
            return;
        } else {

        myTarget.style.display = 'none';
        myTargetPrevious.style.display = 'block';

        setMobileHourToggler('none');
        setShowHours(false);
        setShowInitialDiv('flex');
        setTesto('Prenota il tuo spot in una delle sale da pranzo aziendali');
        }
    };

    return (
        <div className="stanzaWrapper" id={idString}>
            <div className="sala">
                <div className="imgSala" 
                    style={{backgroundImage: `url(${stanze.img})`, backgroundSize: 'cover'}}>
                </div>
                <div className="stanzaBtns">
                    
                    <div className="maxOccupancy">
                        <span>max {stanze.capacity} <FontAwesomeIcon
                        className="bookmarkIcon" 
                        icon={faUsers}
                        size="1x"
                        />
                        </span>
                        <p>{stanze.nomeStanza}</p>
                    </div>
                    
                    <button 
                        className="buttonPrenota btnPrenota"
                        onClick={showChoose}
                        style={{display: showButton}}
                        data-sede={sedeString}
                        data-stanza={salaLuogo[0]}>Prenota <FontAwesomeIcon
                        className="bookmarkIcon" 
                        icon={faBookmark}
                        size="sm"
                        />
                    </button>
                    <button
                        className="buttonPrenota btnAnnulla"
                        style={{display: 'none' }}
                        onClick={hideChoose}>Annulla <FontAwesomeIcon
                        className="bookmarkIcon" 
                        icon={faBookmark}
                        size="sm"
                        />
                    </button>
                    <CheckPrenotation 
                        oraPrenotata={oraPrenotata} 
                        minutiPrenotati={minutiPrenotati} 
                        setTesto={setTesto} 
                        setShowQuestionDelete={setShowQuestionDelete}
                        setShowIcons={setShowIcons}
                        setAnswerYes={setAnswerYes}
                        setAnswerNo={setAnswerNo}
                        setShowQuestionPrenotation={setShowQuestionPrenotation}
                        showOk={showOk}
                        setShowOk={setShowOk}
                        showX={showX}
                        setShowX={setShowX}
                        setShowHours={setShowHours}
                        setMobileHourToggler={setMobileHourToggler}
                        setNomeStanza={setNomeStanza}
                        setNomeSede={setNomeSede}
                        setOraPrenotata={setOraPrenotata}
                        setMinutiPrenotati={setMinutiPrenotati}
                        setShowInitialDiv={setShowInitialDiv}
                        setShowButton={setShowButton}
                        setStanzaId={setStanzaId}
                    />
                </div>
            </div>
        </div>
    );
};