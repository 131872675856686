import React, { useState } from "react";

import { faArrowLeft, faCheckCircle, faTimesCircle, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../../styles/ore.css';
import imgSfondoOre from '../../static/imgSfondoOre2.png';


export default function Ore( props ){

    //props
    const arrayInfos = props.arrayInfos;
    const sede = props.nomeSede;
    const stanza = props.nomeStanza;
    const setShowPrenotation = props.setShowPrenotation;
    const minutiPrenotati = props.minutiPrenotati;
    const setMinutiPrenotati = props.setMinutiPrenotati;
    const oraPrenotata = props.oraPrenotata;
    const setOraPrenotata = props.setOraPrenotata;
    const testo = props.testo;
    const setTesto = props.setTesto;
    const showHours = props.showHours;
    const setShowHours = props.setShowHours;
    const stanzaId = props.stanzaId;
    const showQuestionDelete = props.showQuestionDelete;
    const setShowQuestionDelete = props.setShowQuestionDelete;
    const showIcons = props.showIcons;
    const setShowIcons = props.setShowIcons;
    const answerYes = props.answerYes;
    const setAnswerYes = props.setAnswerYes;
    const answerNo = props.answerNo;
    const setAnswerNo = props.setAnswerNo;
    const showQuestionPrenotation = props.showQuestionPrenotation;
    const setShowQuestionPrenotation = props.setShowQuestionPrenotation;
    const setShowOk = props.setShowOk;
    const setShowX = props.setShowX;
    const showInitialDiv = props.showInitialDiv;
    const setShowInitialDiv = props.setShowInitialDiv;
    const setMobileHourToggler = props.setMobileHourToggler;
    const widthMobile = props.widthMobile;
    const showArrow = props.showArrow;
    const setShowArrow = props.setShowArrow;
    const setScore = props.setScore;

    //hooks
    const [showOkIcon, setShowOkIcon] = useState('none');
    const [showBadIcon, setBadIcon] = useState('none');  

    //user da spedire nel BE
    const user = JSON.parse(localStorage.getItem('user'));

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = dd + '/' + mm + '/' + yyyy;

    //Chiamata API
    const manageDataAPI = '/api/v1/room';


    const inizioPausa = 12;
    const finePausa = 14;

    const askQuestion = (e, time) => {
        e.preventDefault();
        if(widthMobile < 767){
            setShowArrow('none');
        }
        
        //al click renderizzo il testo con la domanda e la scelta
        setTesto('Stai per prenotarti per questo orario. Sei sicuro/a ?')
        setShowQuestionPrenotation('flex');
        setAnswerYes('flex');
        setAnswerNo('flex');

        //acquisisco l'orario
        var eTarget = time.split(':');

        setOraPrenotata(eTarget[0]);
        setMinutiPrenotati(eTarget[1]);
    };

    const abortPrenotation = (e) => {
        e.preventDefault();

        setTesto('Scegli la fascia oraria in cui vuoi occupare la sala scelta di ' + sede[0].toUpperCase() + sede.substr(1).split('_').join(' '))
        setShowQuestionPrenotation('none');
        setAnswerYes('none');
        setAnswerNo('none');
        
        if(widthMobile < 767){
            setShowArrow('block');
        }
        
    };

    const Prenotation = (e) => {
        e.preventDefault();

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
            urlencoded.append("ora", oraPrenotata);
            urlencoded.append("minuti", minutiPrenotati);
            urlencoded.append("place", sede);
            urlencoded.append("room", stanza);
            urlencoded.append("operation", "prenotazione");
            urlencoded.append("name", user.nome);
            urlencoded.append("surname", user.cognome);
            urlencoded.append("email", user.email);
            urlencoded.append("giorno", today);
            urlencoded.append("id", stanzaId)

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
    };

        fetch(manageDataAPI, requestOptions)
            .then(response => {
                response.json()
                if(response.status === 200 || response.status === 201){
                    setTesto('Hai prenotato alle ' + oraPrenotata + ':' + minutiPrenotati + ' nella sede di ' + sede[0].toUpperCase() + sede.substr(1).split('_').join(' ') + '.\nBuon appetito!');
                    setShowOkIcon('block');
                    setShowIcons('block');
                    setShowPrenotation(true);
                    setAnswerNo('none');
                    setAnswerYes('none');
                    setShowOk('block');
                    if(response.status === 200){
                        setScore(prev => prev += 10)
                    }
                    

                    /* localStorage.setItem('prenotazione', oraPrenotata + ':' + minutiPrenotati)
                    localStorage.setItem('sede', sede)
                    localStorage.setItem('stanza', stanza) */
                    /* localStorage.setItem('id', stanzaId) */

                    let stanzaIdElement = document.querySelector('#' + stanzaId + ' .stanzaBtns .reminderPrenotation');
                    stanzaIdElement.style.display = 'flex';
                    let buttonPrenota = document.querySelector('#' + stanzaId + ' .stanzaBtns .btnPrenota');
                    buttonPrenota.style.display = 'none';
                    let buttonAnnulla = document.querySelector('#' + stanzaId + ' .stanzaBtns .btnAnnulla');
                    buttonAnnulla.style.display = 'none';


                } else if(response.status === 400){
                    setTesto('Ops, sembra che non ci siano più posti disponibili o abbia già effettuato una prenotazione');
                    setBadIcon('block');
                    setShowIcons('flex');
                    setAnswerNo('none');
                    setAnswerYes('none');
                }

                if(widthMobile < 767){
                    setTimeout(() => {
                        setMobileHourToggler('none');
                        setShowHours(false)
                    }, [3000])
                }
                
            })
            /* .then(result => console.log(result)) */
            .catch(error => console.log('error', error));
        
    };

    const abortDeletePrenotation = (e) => {
        e.preventDefault();
        
        if(widthMobile < 767){
            setMobileHourToggler('none');
            setShowHours(false)
        }
        setTesto('Hai prenotato alle ' + oraPrenotata + ':' + minutiPrenotati + ' nella sede di ' + sede[0].toUpperCase() + sede.substr(1).split('_').join(' ') + '.\nBuon appetito!')
        setShowQuestionDelete('none');
        setAnswerYes('none');
        setAnswerNo('none');
        setShowIcons('block');
        setShowInitialDiv('flex');
    };

    const DeletePrenotation = (e) => {
        //devo fare la chiamata alla API per eliminare la prenotazione
        e.preventDefault();

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
            urlencoded.append("ora", oraPrenotata);
            urlencoded.append("minuti", minutiPrenotati);
            urlencoded.append("place", sede);
            urlencoded.append("room", stanza);
            urlencoded.append("operation", "cancellazione");
            urlencoded.append("name", user.nome);
            urlencoded.append("surname", user.cognome);
            urlencoded.append("email", user.email);
            urlencoded.append("giorno", today);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
    };

        fetch(manageDataAPI, requestOptions)
            .then(response => {
                response.json()
                if(response.status === 200){
                    setTesto('Prenotazione annullata correttamente');
                    setAnswerYes('none');
                    setAnswerNo('none');
                    setShowIcons('block');
                    setShowOkIcon('block');
                    setShowPrenotation(true);
                    setShowOk('none');
                    setShowX('none');

                    let stanzaIdElement = document.querySelector('#' + stanzaId + ' .stanzaBtns .reminderPrenotation');
                    stanzaIdElement.style.display = 'none';
                    let buttonPrenota = document.querySelector('#' + stanzaId + ' .stanzaBtns .btnPrenota');
                    buttonPrenota.style.display = 'block'
                    let buttonAnnulla = document.querySelector('#' + stanzaId + ' .stanzaBtns .btnAnnulla');
                    buttonAnnulla.style.display = 'none';

                    /* let keysToRemove = ['prenotazione', 'sede', 'stanza']

                    keysToRemove.forEach(key => {
                        localStorage.removeItem(key)
                    }) */

                    localStorage.removeItem('id');

                    setTimeout(() => {
                        setMobileHourToggler('none')
                        setShowHours(false);
                        setTesto('Prenota il tuo spot in una delle sale da pranzo aziendali');
                        setShowIcons('none');
                        setShowOkIcon('none');
                        setShowQuestionPrenotation('none');
                        setShowQuestionDelete('none');
                        setShowPrenotation(false);
                        setShowInitialDiv('flex');
                    }, [2000])
                }
                else if(response.status === 400){
                    setTesto('Ops, qualcosa è andato storto');
                    setAnswerYes('none');
                    setAnswerNo('none');
                    setShowIcons('flex');
                    setBadIcon('block');
                    setShowOkIcon('none');
                }
            })
            /* .then(result => console.log(result)) */
            .catch(error => console.log('error', error));
        
    }

    const BackToHome = (e) => {
        e.preventDefault();
        setMobileHourToggler('none');
        setShowHours(false);
        let stanzaIdElement = document.querySelector('#' + stanzaId + ' .stanzaBtns .reminderPrenotation');
        stanzaIdElement.style.display = 'none';
        let buttonPrenota = document.querySelector('#' + stanzaId + ' .stanzaBtns .btnPrenota');
        buttonPrenota.style.display = 'block'
        let buttonAnnulla = document.querySelector('#' + stanzaId + ' .stanzaBtns .btnAnnulla');
        buttonAnnulla.style.display = 'none';
    };

    return (
        widthMobile > 767 ?
        <div className="chooseHourContainer">
            <h3>{testo}</h3>
            <div className="initialDiv" style={{display: showInitialDiv}}>
                <img src={imgSfondoOre} className="initialImg" alt="imgSfondoOre"/>
            </div>
            <div className="iconCheckContainer" style={{display: showIcons}}>
                <FontAwesomeIcon 
                    className="iconOk"
                    style={{display: showOkIcon}}
                    icon={faCheckCircle}
                    size="2x"
                />
                <FontAwesomeIcon 
                    className="iconBad"
                    style={{display: showBadIcon}}
                    icon={faTimesCircle}
                    size="2x"
                />
            </div>
            <div className="askPrenotation" style={{display: showQuestionPrenotation}}>
            <FontAwesomeIcon 
                    className="iconOk"
                    style={{display: answerYes, cursor: 'pointer' }}
                    onClick={(e) => Prenotation(e)}
                    icon={faCheckCircle}
                    size="2x"
                />
                <FontAwesomeIcon 
                    className="iconBad"
                    style={{display: answerNo, cursor: 'pointer'}}
                    onClick={(e) => abortPrenotation(e)}
                    icon={faTimesCircle}
                    size="2x"
                />
            </div>
            <div className="askDeletion" style={{display: showQuestionDelete}}>
            <FontAwesomeIcon 
                    className="iconOk"
                    style={{display: answerYes, cursor: 'pointer' }}
                    onClick={(e) => DeletePrenotation(e)}
                    icon={faCheckCircle}
                    size="2x"
                />
                <FontAwesomeIcon 
                    className="iconBad"
                    style={{display: answerNo, cursor: 'pointer'}}
                    onClick={(e) => abortDeletePrenotation(e)}
                    icon={faTimesCircle}
                    size="2x"
                />
            </div>
            <div className="oreContainer" style={{display: showHours}}>
                {
                    arrayInfos ? 

                    Object.values(arrayInfos).filter(ora => {
                    
                        let timeSplitted = ora.time.split(":")[0];
                        
                        if(timeSplitted >= inizioPausa && timeSplitted <= finePausa){
                            
                            console.log(timeSplitted)
                            return timeSplitted
                        }
                    }).map(timeSplitted => {
                        
                        let occupancy = timeSplitted.sede[sede][stanza].occupancy;
                        let capacity = timeSplitted.sede[sede][stanza].capacity;
                        return (
                            <div 
                                className="hour"
                                style={{display: showQuestionPrenotation === 'flex' ? 'none': (showQuestionDelete === 'flex') ? 'none' : (showIcons === 'block')? 'none' : (showInitialDiv === 'flex') ? 'none' : 'flex',
                                    cursor: occupancy == capacity ? 'default' : 'pointer'}}
                                onClick={(e) => askQuestion(e, timeSplitted.time)}
                                key={timeSplitted.time}
                                sede={sede}
                                disabled={occupancy == capacity ? true : false}>
                                    <div className="counting">
                                        {occupancy} / {capacity}
                                        <FontAwesomeIcon 
                                            className="iconCounter"
                                            icon={faUsers}
                                        />
                                    </div>
                                    <div className="time">{timeSplitted.time}</div>
                            </div>
                        );
                    })
                    : null
                }
            </div>
        </div> 
        : 
        //Render Mobile
        <div className="chooseHourContainerMobile">
            <div className="chooseHourContainerMobileHeader">
                <FontAwesomeIcon 
                    className="backArrow"
                    style={{display: showArrow}}
                    icon={faArrowLeft}
                    size="1x"
                    onClick={(e) => BackToHome(e)}/>
                <h3>{testo}</h3>
            </div>
            {/* <div className="initialDiv" style={{display: showInitialDiv}}>
                <img src={imgSfondoOre} className="initialImg" alt="imgSfondoOre"/>
            </div> */}
            <div className="iconCheckContainer" style={{display: showIcons}}>
                <FontAwesomeIcon 
                    className="iconOk"
                    style={{display: showOkIcon}}
                    icon={faCheckCircle}
                    size="2x"
                />
                <FontAwesomeIcon 
                    className="iconBad"
                    style={{display: showBadIcon}}
                    icon={faTimesCircle}
                    size="2x"
                />
            </div>
            <div className="askPrenotation" style={{display: showQuestionPrenotation}}>
            <FontAwesomeIcon 
                    className="iconOk"
                    style={{display: answerYes, cursor: 'pointer' }}
                    onClick={(e) => Prenotation(e)}
                    icon={faCheckCircle}
                    size="2x"
                />
                <FontAwesomeIcon 
                    className="iconBad"
                    style={{display: answerNo, cursor: 'pointer'}}
                    onClick={(e) => abortPrenotation(e)}
                    icon={faTimesCircle}
                    size="2x"
                />
            </div>
            <div className="askDeletion" style={{display: showQuestionDelete}}>
            <FontAwesomeIcon 
                    className="iconOk"
                    style={{display: answerYes, cursor: 'pointer' }}
                    onClick={(e) => DeletePrenotation(e)}
                    icon={faCheckCircle}
                    size="2x"
                />
                <FontAwesomeIcon 
                    className="iconBad"
                    style={{display: answerNo, cursor: 'pointer'}}
                    onClick={(e) => abortDeletePrenotation(e)}
                    icon={faTimesCircle}
                    size="2x"
                />
            </div>
            <div className="oreContainer" style={{display: showHours}}>
                {
                    arrayInfos ? 

                    Object.values(arrayInfos).filter(ora => {
                    
                        let timeSplitted = ora.time.split(":")[0];
                        
                        if(timeSplitted >= inizioPausa && timeSplitted <= finePausa){

                            return timeSplitted
                        }
                    }).map(timeSplitted => {
                        
                        let occupancy = timeSplitted.sede[sede][stanza].occupancy;
                        let capacity = timeSplitted.sede[sede][stanza].capacity;
                        return (
                            <div 
                                className="hour"
                                style={{display: showQuestionPrenotation === 'flex' ? 'none': (showQuestionDelete === 'flex') ? 'none' : (showIcons === 'block')? 'none' : (showInitialDiv === 'flex') ? 'none' : 'flex',
                                    cursor: occupancy == capacity ? 'default' : 'pointer'}}
                                onClick={(e) => askQuestion(e, timeSplitted.time)}
                                key={timeSplitted.time}
                                sede={sede}>
                                    <div className="counting">
                                        {occupancy} / {capacity}
                                        <FontAwesomeIcon 
                                            className="iconCounter"
                                            icon={faUsers}
                                        />
                                    </div>
                                    <div className="time">{timeSplitted.time}</div>
                            </div>
                        );
                    })
                    : null
                }
            </div>
        </div> 
    );
};