import React, { useState, useEffect } from "react";

import Luogo from "../luogo/luogo";
import Ore from "../ore/ore";
import '../../styles/lista.css';
import imgLat from '../../static/imgLat2.png';

export default function Lista( props ){

    const sede = props.sede;
    /* console.log('sede in lista', sede) */
    const ricerca = props.ricerca;
    const toggler = props.toggler;
    const widthMobile = props.widthMobile;
    const setWidthMobile = props.setWidthMobile;
    const setScore = props.setScore;
    const nomeSede = props.nomeSede;
    const setNomeSede = props.setNomeSede;
    const nomeStanza = props.nomeStanza;
    const setNomeStanza = props.setNomeStanza;
    const stanzaId = props.stanzaId;
    const setStanzaId = props.setStanzaId;
    const oraPrenotata = props.oraPrenotata;
    const setOraPrenotata = props.setOraPrenotata;
    const minutiPrenotati = props.minutiPrenotati;
    const setMinutiPrenotati = props.setMinutiPrenotati;
    const testo = props.testo;
    const setTesto = props.setTesto;
    const showOk = props.showOk;
    const setShowOk = props.setShowOk;
    
    //hooks per mostrare la grid
    const [showHours, setShowHours] = useState(false);

    //hook per prendere i dati da inserire nelle card del tempo
    const [arrayInfos, setArrayInfos] = useState();
    const [showPrenotation, setShowPrenotation] = useState(false);
    const [showQuestionDelete, setShowQuestionDelete] = useState('none');
    const [showIcons, setShowIcons] = useState('none');
    const [showQuestionPrenotation, setShowQuestionPrenotation] = useState('none');
    const [answerYes, setAnswerYes] = useState('none');
    const [answerNo, setAnswerNo] = useState('none');
    const [showButton, setShowButton] = useState('block');
    const [showX, setShowX] = useState('none');
    const [showInitialDiv, setShowInitialDiv] = useState('flex');
    const [mobileHourToggler, setMobileHourToggler] = useState('none');
    const [hourToggler, setHourToggler] = useState('grid');
    const [showArrow, setShowArrow] = useState('block');

    useEffect(() => {

        UpdateDimensions();

        window.addEventListener("resize", UpdateDimensions);

        return () => {
            window.removeEventListener("resize", UpdateDimensions);
        }
    }, [])

    const UpdateDimensions = () => {
        const widthChange = window.innerWidth;
        setWidthMobile(widthChange)
        if(widthChange < 767){
            setHourToggler('none');
        } else {
            setHourToggler('grid');
        }
    };

    return (
    <div className="fullContainer">
        <section className="cardsContainer">
        {
            
            Object.keys(sede).filter(luogo => { //RICERCA PER SEDE
                if(ricerca === ''){
                    return <Luogo 
                    sedeLuogo={sede[luogo]} 
                    loc={luogo}
                    sede={sede}
                    showHours={showHours} 
                    setShowHours={setShowHours} 
                    setArrayInfos={setArrayInfos} 
                    nomeSede={nomeSede} 
                    setNomeSede={setNomeSede} 
                    nomeStanza={nomeStanza} 
                    setNomeStanza={setNomeStanza} 
                    showPrenotation={showPrenotation}
                    oraPrenotata={oraPrenotata}
                    minutiPrenotati={minutiPrenotati}
                    setTesto={setTesto}
                    setShowQuestionDelete={setShowQuestionDelete}
                    setShowIcons={setShowIcons}
                    setAnswerYes={setAnswerYes}
                    setAnswerNo={setAnswerNo}
                    setShowQuestionPrenotation={setShowQuestionPrenotation}
                    showButton={showButton}
                    setShowButton={setShowButton}
                    showOk={showOk}
                    setShowOk={setShowOk}
                    showX={showX}
                    setShowX={setShowX}
                    toggler={toggler}
                    setShowInitialDiv={setShowInitialDiv}
                    setMobileHourToggler={setMobileHourToggler}
                    widthMobile={widthMobile}
                    setShowArrow={setShowArrow}
                    setOraPrenotata={setOraPrenotata}
                    setMinutiPrenotati={setMinutiPrenotati}
                />

                } else if(luogo.toLowerCase().includes(ricerca.toLowerCase())){
                    return <Luogo 
                    sedeLuogo={sede[luogo]} 
                    loc={luogo}
                    sede={sede}
                    showHours={showHours} 
                    setShowHours={setShowHours} 
                    setArrayInfos={setArrayInfos} 
                    nomeSede={nomeSede} 
                    setNomeSede={setNomeSede} 
                    nomeStanza={nomeStanza} 
                    setNomeStanza={setNomeStanza} 
                    showPrenotation={showPrenotation}
                    oraPrenotata={oraPrenotata}
                    minutiPrenotati={minutiPrenotati}
                    setTesto={setTesto}
                    setShowQuestionDelete={setShowQuestionDelete}
                    setShowIcons={setShowIcons}
                    setAnswerYes={setAnswerYes}
                    setAnswerNo={setAnswerNo}
                    setShowQuestionPrenotation={setShowQuestionPrenotation}
                    showButton={showButton}
                    setShowButton={setShowButton}
                    showOk={showOk}
                    setShowOk={setShowOk}
                    showX={showX}
                    setShowX={setShowX}
                    toggler={toggler}
                    setShowInitialDiv={setShowInitialDiv}
                    setMobileHourToggler={setMobileHourToggler}
                    widthMobile={widthMobile}
                    setShowArrow={setShowArrow}
                    setOraPrenotata={setOraPrenotata}
                    setMinutiPrenotati={setMinutiPrenotati}
                />
                }
            }).map((luogo, i) => {
                /* console.log('luogo nel map di sedeLuogo', luogo) */
                return <Luogo 
                key={i}
                sedeLuogo={sede[luogo]}
                loc={luogo}
                sede={sede}
                showHours={showHours}
                setShowHours={setShowHours}
                setArrayInfos={setArrayInfos} 
                nomeSede={nomeSede} 
                setNomeSede={setNomeSede} 
                nomeStanza={nomeStanza} 
                setNomeStanza={setNomeStanza} 
                showPrenotation={showPrenotation} 
                setStanzaId={setStanzaId}
                oraPrenotata={oraPrenotata}
                minutiPrenotati={minutiPrenotati}
                setTesto={setTesto}
                setShowQuestionDelete={setShowQuestionDelete}
                setShowIcons={setShowIcons}
                setAnswerYes={setAnswerYes}
                setAnswerNo={setAnswerNo}
                setShowQuestionPrenotation={setShowQuestionPrenotation}
                showButton={showButton}
                setShowButton={setShowButton}
                showOk={showOk}
                setShowOk={setShowOk}
                showX={showX}
                setShowX={setShowX}
                toggler={toggler}
                setShowInitialDiv={setShowInitialDiv}
                setMobileHourToggler={setMobileHourToggler}
                widthMobile={widthMobile}
                setShowArrow={setShowArrow}
                setOraPrenotata={setOraPrenotata}
                setMinutiPrenotati={setMinutiPrenotati}
                />
            })
        }
        </section>
        <div 
        className="showBg">
            <img src={imgLat} />
        </div>
    {
        //se la width è maggiore di 767 renderizzo la versione non mobile
    widthMobile > 767 ?
    <div 
        className="chooseHour"
        style={{display: hourToggler}}>
        <Ore 
            arrayInfos={arrayInfos}
            nomeSede={nomeSede}
            nomeStanza={nomeStanza}
            setShowPrenotation={setShowPrenotation} 
            stanzaId={stanzaId}
            oraPrenotata={oraPrenotata}
            setOraPrenotata={setOraPrenotata}
            minutiPrenotati={minutiPrenotati}
            setMinutiPrenotati={setMinutiPrenotati}
            testo={testo}
            setTesto={setTesto}
            showHours={showHours}
            setShowHours={setShowHours}
            setShowQuestionDelete={setShowQuestionDelete}
            showQuestionDelete={showQuestionDelete}
            showIcons={showIcons}
            setShowIcons={setShowIcons}
            answerYes={answerYes}
            setAnswerYes={setAnswerYes}
            answerNo={answerNo}
            setAnswerNo={setAnswerNo}
            setShowQuestionPrenotation={setShowQuestionPrenotation}
            showQuestionPrenotation={showQuestionPrenotation}
            setShowButton={setShowButton}
            setShowOk={setShowOk}
            setShowX={setShowX}
            showInitialDiv={showInitialDiv}
            setShowInitialDiv={setShowInitialDiv}
            setMobileHourToggler={setMobileHourToggler}
            mobileHourToggler={mobileHourToggler}
            hourToggler={hourToggler}
            widthMobile={widthMobile}
            setScore={setScore}
        />
    </div> 
    :
    <div className="chooseHourMobile"
        style={{display: mobileHourToggler}}>
        <Ore 
            arrayInfos={arrayInfos}
            nomeSede={nomeSede}
            nomeStanza={nomeStanza}
            setShowPrenotation={setShowPrenotation} 
            stanzaId={stanzaId}
            oraPrenotata={oraPrenotata}
            setOraPrenotata={setOraPrenotata}
            minutiPrenotati={minutiPrenotati}
            setMinutiPrenotati={setMinutiPrenotati}
            testo={testo}
            setTesto={setTesto}
            showHours={showHours}
            setShowHours={setShowHours}
            setShowQuestionDelete={setShowQuestionDelete}
            showQuestionDelete={showQuestionDelete}
            showIcons={showIcons}
            setShowIcons={setShowIcons}
            answerYes={answerYes}
            setAnswerYes={setAnswerYes}
            answerNo={answerNo}
            setAnswerNo={setAnswerNo}
            setShowQuestionPrenotation={setShowQuestionPrenotation}
            showQuestionPrenotation={showQuestionPrenotation}
            setShowButton={setShowButton}
            setShowOk={setShowOk}
            setShowX={setShowX}
            showInitialDiv={showInitialDiv}
            setShowInitialDiv={setShowInitialDiv}
            setMobileHourToggler={setMobileHourToggler}
            mobileHourToggler={mobileHourToggler}
            hourToggler={hourToggler}
            widthMobile={widthMobile}
            setShowArrow={setShowArrow}
            showArrow={showArrow}
            setScore={setScore}
        />
    </div>
    }
    </div>
    )
};