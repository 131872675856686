import React, { useEffect, useState } from "react";

import '../../styles/luogo.css';
import Stanze from "../stanze/stanze";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from "@fortawesome/free-solid-svg-icons";

export default function Luogo( props ){

    const sedeLuogo = props.sedeLuogo;
    /* console.log('sedeLuogo', sedeLuogo); */
    const sedeString = props.loc;
    const sedeReplace = props.loc.replace(/_/gm, ' ');
    const showHours = props.showHours;
    const setShowHours = props.setShowHours;
    const setArrayInfos = props.setArrayInfos;
    const setNomeSede = props.setNomeSede;
    const nomeSede = props.nomeSede;
    const setNomeStanza = props.setNomeStanza;
    const nomeStanza = props.nomeStanza;
    const setStanzaId = props.setStanzaId;
    const oraPrenotata = props.oraPrenotata;
    const minutiPrenotati = props.minutiPrenotati;
    const setTesto = props.setTesto;
    const setShowQuestionDelete = props.setShowQuestionDelete;
    const setShowIcons = props.setShowIcons;
    const setAnswerYes = props.setAnswerYes;
    const setAnswerNo = props.setAnswerNo;
    const setShowQuestionPrenotation = props.setShowQuestionPrenotation;
    const showButton = props.showButton;
    const setShowButton = props.setShowButton;
    const showOk = props.showOk;
    const setShowOk = props.setShowOk;
    const showX = props.showX;
    const setShowX = props.setShowX;
    const toggler = props.toggler;
    const setShowInitialDiv = props.setShowInitialDiv;
    const setMobileHourToggler = props.setMobileHourToggler;
    const widthMobile = props.widthMobile;
    const setShowArrow = props.setShowArrow;
    const setOraPrenotata = props.setOraPrenotata;
    const setMinutiPrenotati = props.setMinutiPrenotati;
    
    const [starColor, setStarColor] = useState('rgb(130, 130, 130)');

    const putSedeFavorites = () => {

        var unfavDiv = document.querySelector(`.${sedeString}`)

        if(starColor === 'rgb(130, 130, 130)'){

            setStarColor('gold');

            localStorage.setItem(`${sedeString}`, JSON.stringify(sedeLuogo));

        } else {

            setStarColor('rgb(130, 130, 130)')
            
            localStorage.removeItem(`${sedeString}`, sedeLuogo)

            if(toggler == false){
                unfavDiv.classList.add("invisibili");
            } else {
                return;
            }
        }
    };
    
    useEffect(() => {
        let sedeIsFavorite = localStorage.getItem(sedeString)
        if(sedeIsFavorite != null){
            setStarColor('gold');
        }
    }, [])

    return (
        <div className={"sedeWrapper " + sedeString} data-name={sedeString}>
            <div className="category">
                <FontAwesomeIcon 
                    className="favorites"
                    style={{color: starColor}}
                    onClick={putSedeFavorites}
                    icon={faStar}
                />
                <h3>{sedeReplace}</h3>
            </div>

            {

                Object.values(sedeLuogo).map((stanza, i) => {

                    return <Stanze 
                    key={i}
                    salaLuogo={(Object.keys(sedeLuogo))}
                    sala={stanza}
                    id={i}
                    sedeString={sedeString}
                    showHours={showHours}
                    setShowHours={setShowHours} 
                    setArrayInfos={setArrayInfos}
                    setNomeSede={setNomeSede}
                    nomeSede={nomeSede} 
                    setNomeStanza={setNomeStanza}
                    nomeStanza={nomeStanza} 
                    setStanzaId={setStanzaId} 
                    oraPrenotata={oraPrenotata}
                    minutiPrenotati={minutiPrenotati}
                    setTesto={setTesto}
                    setShowQuestionDelete={setShowQuestionDelete}
                    setShowIcons={setShowIcons}
                    setAnswerYes={setAnswerYes}
                    setAnswerNo={setAnswerNo}
                    setShowQuestionPrenotation={setShowQuestionPrenotation}
                    showButton={showButton}
                    setShowButton={setShowButton}
                    showOk={showOk}
                    setShowOk={setShowOk}
                    showX={showX}
                    setShowX={setShowX}
                    setShowInitialDiv={setShowInitialDiv}
                    setMobileHourToggler={setMobileHourToggler}
                    widthMobile={widthMobile}
                    setShowArrow={setShowArrow}
                    setOraPrenotata={setOraPrenotata}
                    setMinutiPrenotati={setMinutiPrenotati}
                />
                })
            }
        </div>
    );
}