import React, { useState } from "react";
import { useHistory } from 'react-router-dom';

import '../../styles/login.css'

import {
    Configuration, LogLevel,
    PublicClientApplication,
    AuthenticationResult,
    AccountInfo,
    EndSessionRequest,
    RedirectRequest,
    PopupRequest,
  } from "@azure/msal-browser";

const AzureActiveDirectoryAppClientId = '864f45b9-ffbf-4433-a5e8-c10e859072e2';
const AzureActiveDirectoryAppClientIdBeta = 'fb2d0564-15af-4fc0-a72e-7f498cc43d06';


const production = {
  appId: AzureActiveDirectoryAppClientId,
  tenant: '51d4e170-e505-4a0d-8885-aaf538e05e75',
  redirect: 'https://spaces.avrgroup.it/'
}

const development = {
  appId: AzureActiveDirectoryAppClientIdBeta,
  tenant: '5bc4a8d1-5c1e-4864-8d6c-f2382c1ff954',
  redirect: 'http://localhost:3000/'
}

export const MSAL_CONFIG = {
  // change development to production before pushing to git
  auth: {
    clientId: production.appId,
    authority: "https://login.microsoftonline.com/" + production.tenant,
    redirectUri: production.redirect,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

  let myMSALObj = new PublicClientApplication(MSAL_CONFIG);
  let account;
  let loginRedirectRequest;
  let loginRequest;

  let isAuthenticationConfigured = false;


export default function Login(){

    const history = useHistory();

    let user = {};

    const goToDashboard = () => {
        myMSALObj
        .loginPopup({
            scopes: [],
            prompt: "select_account",
          })
        .then((resp) => {
          /* console.log('MSAL', resp); */
          if(resp.idToken && resp.account && resp.account.name){

            let accountName = resp.account.name.split(' ');

            user = {
              nome: accountName.slice(0, 1).join(' '),
              cognome: accountName.slice(1, accountName.length).join(' '),
              email: resp.account.username.toLowerCase()
            };

            localStorage.setItem('user', JSON.stringify(user));
            history.push('/dashboard');

          }
        })
        .catch((err) => {
          console.error(err);
        });
    };

    return (
    <div className="loginPage">
        <div className="loginWrapper">
            <div className="imgLogoContainer"></div>
            <button className="buttonLogin" onClick={goToDashboard}>Benvenuti, entrate!</button>
        </div>
    </div>
        );
};