import {BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import Login from './components/login/login';
import Dashboard from './components/dashboard/dashboard';
import Prenotazioni from './components/prenotazioni/prenotazioni';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path ="/" render={props => <Login {...props}/>}  />
        <Route exact path ="/dashboard" render={props => <Dashboard {...props}/>} />
        <Route exact path ="/prenotazioni" render={props => <Prenotazioni {...props} />} />
      </Switch>
    </Router>
  );
}

export default App;
